:root {
  --clr--neutral-100: white;
  --clr--neutral-900: black,
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./css_fonts/Montserrat/Montserrat-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat-bold';
  src: url('./css_fonts/Montserrat/Montserrat-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat-Medium';
  src: url('./css_fonts/Montserrat/Montserrat-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat-light';
  src: url('./css_fonts/Montserrat/Montserrat-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat-thin';
  src: url('./css_fonts/Montserrat/Montserrat-Thin.ttf') format('truetype');
}

html {
  scroll-behavior: smooth;
  /* overflow: hidden; */
}

body {
  font-size: 1.3125rem;
  line-height: 1.6;
}

body,
h1,
h2,
h3,
p {
  margin: 0;
}

h1, h2, h3, h4, h5, p {
  font-family: 'Montserrat';
  line-height: 1.3;
  text-align: center;
}

h1, h2, h3, p {
  margin-bottom: 1rem;
}

img {
  display: block;
  max-width: 100%;
}

a {
  cursor: pointer;
}

.text-center {
  text-align: center;
}

.message-alert {
  margin: 1rem 1rem;
}

header,
section {
  padding: 2rem 0;
}

header {
  height: 100vh;
  color: white;
}

.container-flex {
  display: flex;
  justify-content: center;
}

.header-grid {
  max-width: 90%;
  display: grid;
  align-items: center;
  margin-top: 10em;
  grid-template-columns: minmax(80px, 180px) minmax(80px, 180px) minmax(80px, 180px) minmax(80px, 180px) minmax(80px, 180px);
  grid-template-rows: 100px;
  background-color: rgba(255, 255, 255, 0.80);
  border-radius: 7px;
  box-shadow: 0 15px 30px 1px rgba(8, 43, 66, 0.608);
  padding: 1em;
  font-family: 'Montserrat-medium';
  color: rgba(12, 54, 82, 0.797);
}

@media (max-width: 600px) {
  .header-grid {
    visibility: hidden;
    width: 0;
  }
}

.header-grid p {
  color: rgb(4,119,251);
}

header h1 {
  padding-top: 17rem;
  font-size: 40px;
}

.header-image {
  background-image: url("../src/screenshots/singns.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  background-color: rgba(21, 73, 105, 0.356);
  background-blend-mode: multiply;
  box-shadow: 0 0 500px rgb(8,43,66) inset;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 40%, rgba(0, 0, 0, 1));
  z-index: 1;
  overflow: hidden;
}

header .big.ui.button {
  background-color: rgba(255, 255, 255, 0.836);
  color: rgb(12, 54, 82);
  font-family: 'Montserrat';
  margin-top: 20px;
  border-radius: 25px;
  position: relative;
  z-index: 2;
}

.bg-primary {
  background-color: rgb(8,43,66);
}

.bg-secondary {
  background-color: rgb(6, 34, 53);
}

.bg-dark {
  background-color: rgb(4,19,41);
}

.bg-verydark {
  background-color: rgba(8, 43, 66, 0.905);
}

.green {
  color: green;
  font-family: 'Montserrat';
}

.orange {
  color: orange;
}

.red {
  color: red;
}

.white {
  color: white;
  z-index: 2;
  position: relative;
}

.light-blue {
  color: rgb(103,114,229);
}

.big.ui.button {
  background-color: rgb(68,105,129);
  padding: 1.4rem 3rem;
  color: white;
  font-family: 'Montserrat-bold';
  letter-spacing: 1px;
}

/* Sign in box, Landing page */
.signin-box {
  border-radius: 5px;
  position: fixed;
  top: 2rem;
  margin: auto;
  width: 80%;
  padding: 10px;
  text-align: center;
  transition: opacity 0.1s linear;
}

.signin-box img {
  margin: 2rem 0 3rem 0;
  margin-inline: auto;
  max-width: 80%;
}

.ui.attached.tabular.menu a {
  font-family: 'Montserrat';
  color: white;
  background-color: rgba(0, 0, 0, 0.253);
}

.ui.attached.tabular.menu a:hover {
  background-color: rgb(153,232,231);
  color: white;
}

.ui.attached:not(.message)+.ui.attached.segment:not(.top) input {
  margin-bottom: 2rem;
  border-radius: 15px;
  font-size: 14px;
  width: 20em;
}

.ui.attached:not(.message)+.ui.attached.segment:not(.top) {
  overflow: scroll;
  padding-bottom: 4em;
}

.ui.attached:not(.message)+.ui.attached.segment:not(.top)::-webkit-scrollbar {
  display: none;
}

.signin-box i {
  position: absolute;
  right: 1.5rem;
  top: 1.5rem;
  font-size: 18px;
  color: rgba(19, 66, 100, 0.74);
  cursor: pointer;
}

.signin-box i:hover {
  color: rgb(153,232,231);
}

/* SIGN IN BOX DESKTOP SCREEN */
@media (min-width: 40em) {
  .signin-box {
    position: relative;
    max-width: 32rem;
  }

  .ui.attached:not(.message)+.ui.attached.segment:not(.top) {
    max-height: 610px;
  }

  .signin {
    top: 4rem;
    position: fixed;
    z-index: 2000;
  }
}

.chart-area {
  height: 410px!important; /* Adjust as needed */
}

.equityCurveButtons {
  display: flex;
}

.equityCurveButtons div {
  margin-left: 10px;
  cursor: pointer;
}

.equityButtonActive {
  color: rgb(4,119,251);
}

.fas.fa-circle.red {
  color: rgba(233, 133, 93, 0.719);
}

.fas.fa-circle.green {
  color: rgba(75,192,192,0.4);
}

/* EXCEL  */
#file-uplaod-button {
  display: none;
}

/* PAYWALL */

.paywall-up {
  position: absolute;
  right: 0;
  top: -30rem;
  height: 0;
  opacity: 0;
}

.paywall {
  position: fixed;
  top: 0rem;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.499);
  z-index: 10000;
  padding: 0;
  display: flex;
  text-align: center;
  backdrop-filter: blur(8px);
  transition: all 1s ease-in-out;
  opacity: 1;
}

.paywallInner {
  width: 600px;
  background-color: rgba(255, 255, 255, 0.209);
  height: 14rem;
  margin: auto auto;
  border-radius: 18px;
  padding: 0.8rem;
  min-width: 360px;
}

.paywall .oval-button-white {
  padding: 1em;
  border-radius: 50px;
  cursor: pointer;
  transition: transform .1s;
  width: 250px;
}

.paywall .oval-button-white:hover {
  transform: scale(1.1);
  box-shadow: 0 3px 5px 1px rgba(8, 43, 66, 0.25);
}

#create-checkout-session {
  margin: auto auto;
}

.paywallInner h5 {
  padding: 0.5em;
  border-top: solid 1px lightgrey;
}

.paywall-most-inner {
  position: relative;
  background-color: white;
  border-radius: 8px;
  padding: 2rem;
  height: 100%;
}

.oval-button-white i {
  position: relative;
  margin: -2.3rem 0 0 0;
  top: .8rem;
  font-size: 46px;
  color: rgb(103,114,229);
}

.paywall-cancel {
  position: absolute;
  color: grey;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
}

/* SUCCESS PAGE */

.success-page {
  padding: 0.5rem 1rem .5rem 1rem;
  text-align: center;
  box-shadow: 0 3px 5px 1px rgba(8, 43, 66, 0.25);
  margin-bottom: 2em;
}

.success-page h1 {
  font-family: 'Montserrat-bold';
  margin: 2rem;
  font-size: 2rem;
}

.success-page .box {
  margin: 2rem 0;
  margin-inline: auto;
  min-width: 400px;
  width: 50%;
  padding: 2rem 2rem 0rem 2rem;
  background-color: rgb(247, 246, 246);
}

.success-page p {
  font-size: 13px;
  padding-bottom: 1rem;
}

/* TERMS OF SERVICE */

.terms h5, .terms p {
  text-align: left;
}

.terms h5 {
  margin-top: 2em;
}

.terms p {
  line-height: 1.4;
}

.ui.checkbox input.hidden+label {
  margin-right: 0.5em;
}

.text-verydark {
  color: rgba(13, 75, 117, 0.866)!important;
}
